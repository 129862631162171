import { CLIENT, GTN } from "./constants";
import { functions } from "./firebase";

const MENS_CATEGORY = "MNS";
const UNISEX_CATEGORY = "UNI";
const WOMENS_CATEGORY = "WNS";
const JUNIOR_CATEGORY = "JNR";
const DEFAULT_CATEGORY = "NON";

const sendOrderConfirmationEmail = functions.httpsCallable(
  "httpsOnCall-sendOrderConfirmationEmail"
);
const monogramTapesCallable = functions.httpsCallable(
  "httpsOnCall-getMonogramTapes"
);

export const getMonogramTapes = async () => {
  const result = await monogramTapesCallable();
  return result.data;
};

export const asyncFilter = async (arr, predicate) => {
  const results = await Promise.all(arr.map(predicate));

  return arr.filter((_v, index) => results[index]);
};

export const getEncodedProductColor = (product) => {
  if (!product) return;
  return encodeURIComponent(product.colorCode);
};

export const getEncodedProductStyle = (product) => {
  if (!product) return;
  return encodeURIComponent(product.styleCode);
};

export const getTitleFromGenderCode = (gender) => {
  switch (gender) {
    case "MNS":
      return "MEN'S";
    case "WNS":
      return "WOMEN'S";
    case "JNR":
      return "JUNIOR";
    case "UNI":
      return "UNISEX";
    default:
      return gender;
  }
};

export const getGenderFromTitle = (title) => {
  switch (title) {
    case "MEN'S":
      return MENS_CATEGORY;
    case "WOMEN'S":
      return WOMENS_CATEGORY;
    case "JUNIOR":
      return JUNIOR_CATEGORY;
    case "UNISEX":
      return UNISEX_CATEGORY;
    case "CATEGORIES":
      return DEFAULT_CATEGORY;
    default:
      return title;
  }
};

// Get the price margin of a product depending on the label and category
export const getPriceMargin = (product) => {
  const CLUBS_MARGIN = 0.3;
  const AUDIO_MARGIN = 0.3;
  const FOOTWEAR_MARGIN = 0.4;
  const GOLF_BAGS_MARGIN = 0.4;
  const APPAREL_MARGIN = 0.5;
  const ACCESSORIES_MARGIN = 0.5;
  const HEADWEAR_MARGIN = 0.5;
  const DEFAULT_MARGIN = 0.5;

  const AUDIO = "AUDIO";
  const BAGS = "BAGS";
  const HEADWEAR = "HEADWEAR";
  const GOLF_CLUB_BAGS = "GOLF CLUB BAGS";
  const CLUBS = "CL";
  const ACCESSORIES = "AC";
  const FOOTWEAR = "FT";
  const WA = "WA";
  const APPAREL = "AP";

  // Can't rely on label = WA for bags
  if (product.category === "GOLF CLUB BAGS") {
    return GOLF_BAGS_MARGIN;
  }
  switch (product.category) {
    case AUDIO:
      return AUDIO_MARGIN;
    case GOLF_CLUB_BAGS:
      return GOLF_BAGS_MARGIN;
    case HEADWEAR:
      return HEADWEAR_MARGIN;
    default:
      switch (product.label) {
        case CLUBS:
          return CLUBS_MARGIN;
        case ACCESSORIES:
          return product.category === BAGS ? AUDIO_MARGIN : ACCESSORIES_MARGIN;
        case FOOTWEAR:
          return FOOTWEAR_MARGIN;
        case WA:
          return product.category === BAGS ? GOLF_BAGS_MARGIN : DEFAULT_MARGIN;
        case APPAREL:
          return APPAREL_MARGIN;
        default:
          return DEFAULT_MARGIN;
      }
  }
};

// Price strings are prepended with a dollar sign by default unless there are custom units
export const getPriceString = (price, units, addLogo) => {
  // Default to 0 if price is not defined
  price = !!price ? price : 0;

  const shouldAddLogoPrice = addLogo && units !== "Units" && CLIENT === GTN;
  const adjustedPrice = shouldAddLogoPrice ? price + 5 : price;

  const dollars = `$${parseFloat(adjustedPrice).toFixed(2)}`;
  if (!units) return dollars;

  // Remove the s
  const singularize = (word) => {
    return word.charAt(word.length - 1) === "s"
      ? word.charAt(word.length - 2) === "e"
        ? // Remove "es" at the end
          word.substr(0, word.length - 2)
        : // Remove "s" at the end
          word.substr(0, word.length - 1)
      : // Don't remove anything
        word;
  };
  // Use singular unit name for a quantity of 1
  units = adjustedPrice === 1 ? singularize(units) : units;
  const custom = `${parseInt(adjustedPrice)} ${units}`;
  return custom;
};
export const formatNewDate = (dateString) => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  // Garantir que o mês e o dia tenham dois dígitos
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  return `${formattedMonth}.${formattedDay}.${year}`;
};

export function formatTimestamp(timestamp) {
  // Verificar se o objeto timestamp está definido e possui a propriedade 'seconds'
  if (!timestamp || !timestamp?.seconds) {
    return "Timestamp inválido";
  }

  // Extrair segundos e nanosegundos do objeto
  const { seconds: sec, nanoseconds: nsec } = timestamp;

  // Converter segundos e nanosegundos para milissegundos
  const milliseconds = sec * 1000 + nsec / 1e6;

  // Criar o objeto Date a partir do timestamp em milissegundos
  const date = new Date(milliseconds);

  // Formatando a data em um formato legível
  const year = date.getUTCFullYear();
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + date.getUTCDate()).slice(-2);
  const hours = ("0" + date.getUTCHours()).slice(-2);
  const minutes = ("0" + date.getUTCMinutes()).slice(-2);
  const seconds = ("0" + date.getUTCSeconds()).slice(-2);
  const millis = ("00" + date.getUTCMilliseconds()).slice(-3);

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${millis} UTC`;
}

export const dateInRange = (startDate, endDate) => {
  const now = new Date();

  if (
    typeof startDate === "object" &&
    "seconds" in startDate &&
    typeof endDate === "object" &&
    "seconds" in endDate
  ) {
    const before = new Date(startDate?.seconds * 1000);
    const after = new Date(endDate?.seconds * 1000);
    return before < now && now < after;
  }
  if (typeof startDate === "object" && "seconds" in startDate) {
    const before = new Date(startDate?.seconds * 1000);
    const after = new Date(endDate).getTime();
    return before < now && now < after;
  }
  if (typeof endDate === "object" && "seconds" in endDate) {
    const before = new Date(startDate).getTime();
    const after = new Date(endDate?.seconds * 1000);
    return before < now && now < after;
  }

  const before = new Date(startDate).getTime();
  const after = new Date(endDate).getTime();
  return before < now && now < after;
};

// Returns the nzn string that corresponds to a size in a size scale
export const getNzForSizeInSizeScale = (size, sizeScale) => {
  return `nz${sizeScale.findIndex((s) => s === size) + 1}`;
};

export const getBitmap = (fileName) => {
  if (fileName?.includes("https")) return fileName;
  return `https://www.vrlinkcorp.com/data/${CLIENT}/bitmaps/${encodeURI(
    fileName
  )}`;
};

// TODO send an order summary email
// Sends a confirmation email to a user with their order summary
export const sendConfirmationEmail = async (orderID) => {
  console.log(`Sending order confirmation email`);
  console.log("envio de confirmação de email",orderID);
  
  sendOrderConfirmationEmail({ orderID, cc: true }).catch((err) => {
    console.log(`Error sending confirmation email: ${err}`);
  });
};

// Capitalizes the first letter in every word
export const capitalizeFirstLetter = (sentence) => {
  return sentence
    ?.toLowerCase()
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

export const formatDate = (date) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const day = days[date.getDay()];
  const month = months[date.getMonth()];
  const theDate = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours() % 12;
  const ampm = date.getHours() > 12 ? "PM" : "AM";
  const minutes = date.getMinutes();
  const result = `${day}, ${month} ${theDate}, ${year} ${hours}:${minutes} ${ampm}`;
  return result;
};

// Deleted the item within an object with a given key
export const omit = (object, key) => {
  const newObject = Object.keys(object)
    .filter((k) => k !== key)
    .reduce((o, k) => {
      o[k] = object[k];
      return o;
    }, {});
  return newObject;
};
